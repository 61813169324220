@import "../../../../scss/presets/colors";
@import "../../../../scss/presets/mixins";
@import "../../../../scss/presets/consts";

.product__custom__topContainer{
  margin-bottom: 35px;

  .product__custom__imageArea{
    width: 637px;
    margin: auto;
    display: block;
  }
}

.product__additionalInfo{
  .product__custom__additionalInfo-title{
    text-align: center;
    padding-bottom: 48px;
    width: 100%;

    p{
      @include font($Color30, 700, 30px, 36px);
    }
  }
  .product__custom__additionalInfo-text {
    p{
      line-height: 22px;
    }
  }

  .product__custom_additionalInfo-video_container{
    margin-top: 54px;
    margin-bottom: 32px;
    width:100%;

    iframe{
      width:100%;
      height: 662px;
    }
  }
}
@media screen and (max-width: $md) {
  .product__custom__topContainer{
    margin-bottom: 35px;

    .product__custom__imageArea{
      max-width: 637px;
      width: 100%;
      height:auto;
    }
  }
}

@media screen and (max-width: $lg) {
  .product__custom__topContainer {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: $sm) {
  .product__custom__topContainer {
    margin-bottom: 65px;
  }

  .product__additionalInfo {
    .product__custom__additionalInfo-title {
      padding: 62px 20px 40px 20px;
    }

    .product__custom__additionalInfo-text {
      padding: 0 20px 20px 20px;
    }

    .product__custom_additionalInfo-video_container{
      padding-left: 20px;
      padding-right: 20px;
    }
  }

}
