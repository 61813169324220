@import '../../../../scss/presets/colors';
@import '../../../../scss/presets/mixins';

.blogNavigationButton {
  width: 144px;
  height: 40px;
  margin-top: 25px;
  border: none;
  border-radius: 44px;
  background-color: $Color51;
  @include font($ColorFF, 400, 14px, 20px);
  @include flexAlignItem(center, center);
  cursor: pointer;

  &__disable {
    background-color: $Color3F;
    cursor: not-allowed;
  }
}