@import '../../../../scss/presets/colors';
@import '../../../../scss/presets/mixins';
@import '../../../../scss/presets/consts';

.headerButton {
    padding: 5px 20px;
    background-color: $Color23;
    outline: none;
    border: none;
    border-radius: 44px;
    cursor: pointer;
    @include appShadow();
    text-decoration: none;
    @include font($ColorFF, 900, 20px, 24px);
    @include hoverOpacityStyle();
    z-index: 101;
    transition-duration: 0.25s;
    opacity: 1;
}

.headerButton__activeLink {
  background-color: $Color51;
}
@media screen and (max-width: $md) {
    .headerButton {
        @include font($ColorFF, 900, 16px, 20px);
        padding: 5px 16px;
    }
}



@media screen and (max-width: $sm) {
    .headerButton {
        display: block;
        width: 100%;
        text-align: left;
        line-height: 40px;
        border-radius: 44px 0 0 44px;
        padding-left: 40px;
        & a {
            box-shadow: none;
            padding: 0;
        }

    }

    .headerButton__activeLink {
        color: $Color1F;
        background-color: $Color23;
        display: block;
        line-height: 40px;
        padding-left: 40px;
        & a {
            box-shadow: none;
        }
    }
}
