@import '../../../../scss/presets/colors';
@import '../../../../scss/presets/mixins';

.videoArea {
  @include flexAlignItems(column, nowrap, center, center);
  width: 100%;

  & > button {
    margin-bottom: 10px;
    align-self: flex-end;
  }

  &-video {
    width: 100%;
    height: 60vh;
    margin: 20px 0;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}