@import '../../../scss/presets/colors';
@import '../../../scss/presets/mixins';
@import '../../../scss/presets/consts';

.header {
  padding: 0 0 70px;
  background: url("../../../assets/img/backgrounds/menuBG.png") no-repeat,  $Color51 ;
  background-size: 100% 100%;
  margin-top: 40px;
  & > .app__container {
    @include flexAlignItem(flex-end, center);
  }
}

@media screen and (max-width: $lg) {
  .header {
    padding: 0 0 60px;
    background-size: 100% auto;
    margin-top: 40px;
    & > .app__container {
      @include flexAlignItem(flex-start, center);
    }
  }
}
@media screen and (max-width: $md) {
  .header {
    margin-top: 50px;
    background-size: 100% 100%;
    width: 100%;
  }
}
@media screen and (max-width: $sm) {
  .header {
    margin-top: 25px;
    background-size: 100% 100%;
    width: 100%;
    padding-bottom: 16px;
  }
}

