@import '../../../scss/presets/colors';
@import '../../../scss/presets/mixins';
@import '../../../scss/presets/consts';

.blogList {
  background: url('../../../assets/img/backgrounds/bgWave.jpg') no-repeat center top;
  background-size: 100% 100%;
  & > .app__container {
    @include flexAlignItems(column, nowrap, flex-start, flex-start);
  }

  &__cardsArea {
    @include flexAlignItems(row, wrap, flex-start, flex-start);
    gap: 10px;
    padding-top: 30px;
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: $md) {
  .blogList {
    background: url('../../../assets/img/backgrounds/bgWave-t.jpg') no-repeat center top;
    background-size: contain;
  }
}

@media screen and (max-width: $sm) {

  .blogList {
    background: url('../../../assets/img/backgrounds/bgWave-m.jpg') no-repeat center top;
    background-size: contain;
    &__cardsArea {
      @include flexAlignItems(column, wrap, flex-start, center);
      display: flex;
    }
  }
}