@import '../../../scss/presets/colors';
@import '../../../scss/presets/mixins';
@import '../../../scss/presets/consts';

.knowledgeQA {
  background: linear-gradient(180deg, #51C1EA 0%, #EEFCFE 70.83%);

  &__cardsArea {
    margin: 25px 0;
    border-radius: 21px;
    @include appShadow();
    overflow: hidden;
  }
}