@import '../../../scss/presets/colors';
@import '../../../scss/presets/mixins';
@import '../../../scss/presets/consts';

.knowledgeFiles {
  background: linear-gradient(180deg, #51C1EA 0%, #EEFCFE 70.83%);
  & > .app__container {
    @include flexAlignItems(column, nowrap, flex-start, flex-start);
  }

  &__cardsArea {
    @include flexAlignItems(row, wrap, space-between, center);
    width: 100%;
    z-index:40;
  }
}

@media screen and (max-width: $md) {
  .knowledgeFiles {
    &__cardsArea {
      @include flexAlignItems(column, nowrap, space-between, center);
    }
  }
}