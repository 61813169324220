@import '../../../../scss/presets/colors';
@import '../../../../scss/presets/mixins';
@import '../../../../scss/presets/consts';

.knowledgeBlockLinkButton {
  cursor: pointer;
  min-width: 195px;
  min-height: 255px;
  background-color: $ColorFF;
  border-radius: 31px;
  @include flexAlignItem(center, center);
  @include font($Color23, 900, 20px, 28px);
  position: relative;
  @include hoverBoxShadowStyle();

  &__fakeLink {
    width: 230px;
    height: 60px;
    position: absolute;
    bottom: -30px;
    left: -18px;
    background-color: $Color23;
    @include font($ColorFF, 900, 20px, 24px);
    @include flexAlignItems(row, nowrap, space-around, center);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 83px;
  }
}

@media screen and (max-width: $md) {
  .knowledgeBlockLinkButton {
    min-width: 180px;
    &__fakeLink {
      max-width: 230px;
      left:unset;
      width: 110%;
    }
  }
}

@media screen and (max-width: $sm) {
  .knowledgeBlockLinkButton {
    max-width: 195px;
    margin-bottom: 60px;
  }
}