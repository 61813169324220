@import '../../../../scss/presets/colors';
@import '../../../../scss/presets/mixins';
@import '../../../../scss/presets/consts';

.blogBlockCard {
  max-width: 470px;
  height: 520px;
  flex-basis: 32%;
  width: 100%;
  border-radius: 31px;
  background-color: $ColorEE;
  overflow: hidden;
  @include flexAlignItems(column, nowrap, flex-start, flex-start);
  cursor: pointer;
  z-index: 50;
  @include hoverBoxShadowStyle();

  img {
    width: 100%;
    height: 225px;
    object-fit: cover;
    border-radius: 31px;
  }

  &__textArea {
    padding: 32px 32px 0 32px;
    flex-grow: 1;

    h6 {
      @include font($Color23, 900, 20px, 24px);
      margin-bottom: 16px;
    }

    p {
      @include font($Color23, 400, 16px, 19px);
      margin-bottom: 16px;
    }
  }

  &__button {
    padding: 10px 20px;
    border-radius: 44px;
    border: none;
    outline: none;
    background-color: $Color82;
    @include font($ColorFF, 400, 14px, 19px);
    font-style: italic;
    align-self: flex-end;
    margin-bottom: 15px;
    margin-right: 26px;
  }
}

@media screen and (max-width: $md) {
  .blogBlockCard {
    flex-basis: 49%;
    max-width: 420px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: $sm) {
  .blogBlockCard {
   flex-basis: 100%;
    width: 100%;
    max-width: unset;
    &__textArea {
      padding: 16px 32px 0 32px;
    }
  }
}