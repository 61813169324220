@import '../../../scss/presets/colors';
@import '../../../scss/presets/mixins';
@import '../../../scss/presets/consts';

.navBar {
  margin-top: -10px;
  margin-right: 50px;
  & img {
    display: none;
  }
  & > ul {
    @include flexAlignItems(row, nowrap, flex-start, center);
    list-style: none;
    z-index: 100;
    margin: 0;

    & > li {
      z-index: 100;
    }

    & > li:not(:first-child), & > button {
      margin-left: 20px;
    }
  }
  &__mobileDropDown {
    display: none
  }
}

@media screen and (max-width: $lg) {
.navBar {
  margin-top: -13px;
  margin-left: auto;
  margin-right: 0;
  & > ul {
    padding-left: 0;
    margin: 0;
    margin-top: -6px;
      & > li:not(:first-child), & > button {
        margin-left: 7px;
      }
    }
  }
}

@media screen and (max-width: $md) {
  .navBar {
    margin-top: -28px;
    & > ul {
      margin: 0;
      & > li:not(:first-child), & > button {
        margin-left: 10px;
      }
    }
  }
}

@media screen and (max-width: $sm) {
  .navBar {
    margin-left: auto;
    text-align: right;
   margin-top: -10px;
    z-index: 55;
    overflow: hidden;
    &__mobileDropDown {
      display: block;
      padding: 0;
      width: 100%;
      line-height: 52px;
      border-radius: 44px 0 0 44px;
      text-align: left;
      list-style: none;
      margin: 0 0 5px 0;
      & li:nth-child(2), li:nth-child(3) {
        & a {
          border-radius: 0;
        }
      }
        & li:last-child {
          a {
          border-radius:0 0 0 44px !important;
        }
      }

      & a {
        box-shadow: none;
        background-color:#234E9B;
        opacity: 1;

        &:hover {
          opacity: 0.5;
          transition-duration: 0.25s;
        }
      }

      & li:not(:last-child) {
        margin-bottom: 0 !important;
      }
    }

    &__button {
      padding: 5px 20px 0 40px;
      background-color:#234E9B;
      border-radius: 44px 0 0 0 !important;
      outline: none;
      border: none;
      cursor: pointer;
      text-decoration: none;
      color: #FFFFFF;
      font-weight: 900;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 0 !important;
    }

    & > ul {
      flex-direction: column;
      z-index: 100;
      margin: 20px 0 0 40px;

      & li {
        width: 100%;
        margin-bottom: 5px;
        line-height: 52px;
      }

      & > li:not(:first-child), & > button {
        margin-left: unset;
      }
    }
    & img {
      margin-right: 20px;
      display: inline;
      cursor: pointer;

    }
    &__list {
      display: none !important;
      position: fixed;
      left: 0;
      right: 0;
      z-index: 80;
    }

    &__list-active {
      display: block !important;
      position: fixed;
      left: 0;
      right: 0;
      z-index: 4;
    }
  }
}