$ColorFF: #FFFFFF;
$ColorF8: #F8F7F8;
$ColorEE: #EEFCFE;
$Color5F: #E5F6FC;
$Color51: #51C1EA;
$Color1F: #1FB4FC;
$Color23: #234E9B;
$ColorEB: #EB5757;
$Color90: #90261F;
$ColorF4A: #F4A508;
$ColorF4D: #F4DC08;
$Color7B: #7B2F80;
$ColorBA: #BA7EB4;
$Color82: #82B74B;
$Color30: #302F2F;
$Color3F: #3F484F;

$ColorBGGradient: linear-gradient(180deg, $Color51 0%, $ColorEE 33%, $ColorFF 66%)