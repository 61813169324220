@import "../../../../scss/presets/colors";
@import "../../../../scss/presets/mixins";
@import "../../../../scss/presets/consts";

.blogBlock {
  margin-top: 25px;

  &__cardsArea {
    @include flexAlignItems(row, nowrap, flex-start, center);
    gap: 13px;
    padding-top: 0;
  }
  & div.mainPageLinkButton {
    background: url("../../../../assets/img/backgrounds/header-tablet.png") no-repeat bottom, rgba(81, 193, 234, 0.28) ;
    background-size: 100% 100%;
  }
}

@media screen and (max-width: $md) {
  .blogBlock {
    &__cardsArea {
      @include flexAlignItems(row, wrap, space-between, center);
      width: 100%;
    }
    & div.mainPageLinkButton {
      background: url("../../../../assets/img/backgrounds/header-tablet.png") no-repeat bottom, rgba(81, 193, 234, 0.51) ;
      background-size: 100% auto;
    }
  }
}
@media screen and (max-width: $sm) {
  .blogBlock {
    &__cardsArea {
      @include flexAlignItems(column, wrap, flex-start, center);
      gap: 13px;
    }
    & div.mainPageLinkButton {
     background: url("../../../../assets/img/backgrounds/header-mobile.png") no-repeat bottom, rgba(81, 193, 234, 0.51) ;
      background-size: 100% 100%;
    }
  }
}