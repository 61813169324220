@import '../../../scss/presets/colors';
@import '../../../scss/presets/mixins';
@import '../../../scss/presets/consts';

.blockTitle {
  @include flexAlignItems(row, nowrap, flex-start, flex-end);
  margin-bottom: 46px;
  position: relative;
  z-index:50;

  &__image {
    width: 85px;
    height: 70px;
    margin-right: 2px;
    & img {
      width: 100%;
      height: 100%
    }
  }

  &__text {
    @include font($Color23, 700, 32px, 38px);
    @include flexAlignItems(column, nowrap, space-between, flex-start);

    & > span {
      font-weight: 300;
    }
  }
}

@media screen and (max-width: $md) {
  .blockTitle {
    margin-bottom: 17px;
  }
}

@media screen and (max-width: $sm) {
  .blockTitle {
    &__text {
      @include font($Color23, 700,16px, 19px);
    }
    &__image {
      width: 42px;
      height: 35px;
      margin-right: 5px;
    }

  }
}

