@import '../../../scss/presets/colors';
@import '../../../scss/presets/mixins';
@import '../../../scss/presets/consts';

.product {
  background: url('../../../assets/img/backgrounds/bgWave.jpg') no-repeat center top;
  background-size: 100% 783px;
  &__mainInfo {
    margin-top: 25px;
    position: relative;
    z-index: 50;
    & > div:nth-child(1), & > div:nth-child(2) {
      @include flexAlignItems(column, nowrap, flex-end, flex-start);
    }

    & > div:nth-child(3) {
      margin-left: -5%;
      margin-top: auto;
      img {
        max-width: 637px;
        width: 130%;
        margin: 0 auto;
      }
    }
  }

  &__additionalInfo {
    padding: 50px 40px 30px 40px;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(166, 223, 244, 0.03) 51.04%, rgba(81, 193, 234, 0.12) 100%);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 54px;
    margin-top: 15px;
    &-hide > div:last-child {
      display: flex !important;
    }

    &-title {
      @include font($Color30, 700, 30px, 36px);
      margin-bottom: 50px;
      text-transform: uppercase;
    }

    &-subTitle {
      @include font($Color23, 900, 20px, 24px);
    }
    &-titleUp {
      display: none;
    }

    &-mainList {
      margin-top: 20px;
      margin-bottom: 50px;
      list-style: disc;
      padding-left: 15px;
    }

    &-subList {
      list-style: disc;
      padding-left: 15px;
    }

    p {
      @include font($Color30, 400, 16px, 19px);
    }

    p:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__imageArea {
    display: none;
  }
  &__additionalGrid {
    display: none !important;
    margin: 26px auto 0 auto;
    justify-content: center;
  }
}

@media screen and (max-width: $lg) {
  .product {
    &__imageArea {
      display: block;
    }
    &__mainInfo {
      & > div:nth-child(2) {
       width: 100%;
        & img {
          width: 100%;
        }
      }
      & > div:nth-child(3) {
       display: none;
      }
    }
    &__additionalGrid {
      display: flex !important;
    }
    &__additionalInfo {
        &-hide > div:last-child {
          display: none !important;
        }

    }
  }
}
@media screen and (max-width: $md) {
  .product {
    background: url('../../../assets/img/backgrounds/bgWave-t.jpg') no-repeat center top;
    background-size: contain;
    &__additionalInfo {
      &-hide > div:last-child {
        display: none !important;
      }
    }
    &__additionalGrid {
      display: flex !important;
    }
  }
}
@media screen and (max-width: $sm) {
  .product {
    background: url('../../../assets/img/backgrounds/bgWave-m.jpg') no-repeat center top;
    background-size: 100% 340px;
    &__imageArea {
      display: none;
    }
    &__mainInfo {
      & > div:nth-child(2) {
        width: 100%;
        order:3;

        & img {
          height: auto;
          width: 100%;
        }
      }
      & > div:nth-child(3) {
        display: flex;
        flex-direction: column;
        order: 1;
        margin: 0 auto;

        & img {
          width: 100%;
          max-width: 430px;
        }
      }

      & > div:nth-child(1) {
        order: 2;
      }
    }
    &__additionalGrid {
      display: none !important;
    }
    &__additionalInfo {
      padding:0;
      margin-top: 0;
        &-hide > div:last-child {
          display: flex !important;

      }
      &-title {
        display: none;
      }
      &-titleUp {
        display: block;
        padding-left: 16px;
        @include font($Color30, 700, 30px, 36px);
        margin-bottom: 30px;
        margin-top: 50px;
      }
      & p {
        padding-top: 0;
      }
      &-subList {
        list-style:disc;
      }
      &-mainList {
        margin-bottom: 20px;
      }
      & div div {
        justify-content: center;
        & button {
          margin-bottom: 32px;
          @include font($ColorFF, 700, 16px, 20px);
          padding: 20px 24px;
          & div {
            width: 26px;
            height: 26px;
          }
        }
      }
      & ul.buyButton__dropZone {
        max-width: unset;
        top: 20px;
        padding: 28px 0 16px 0;
      }
    }
    &__container {
      padding: 16px 0 0 0;
    & div {
      padding: 0 20px 20px 20px;
    }
    }
  }
}