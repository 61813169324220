@import '../../../../scss/presets/colors';
@import '../../../../scss/presets/mixins';
@import '../../../../scss/presets/consts';

.videoCard {
  width: 350px;
  max-width: 350px;
  height: 290px;
  max-height: 290px;
  cursor: pointer;
  z-index: 100;
  flex-basis: 24%;
  flex-shrink: 0;
  flex-grow: 1;


  &__photo {
    width: 100%;
    max-height: 195px;
    object-fit: cover;
    @include hoverBoxShadowStyle();
  }

  &__title {
    margin-top: 18px;
    @include font($Color23, 900, 20px, 24px);
  }
}

@media screen and (max-width: $lg) {

  .videoCard {
    flex-basis: 32%
  }
}

@media screen and (max-width: $md) {

  .videoCard {
    flex-basis: 50%;
  }
}
@media screen and (max-width: $sm) {

  .videoCard {
    width: 100%;
    max-width: 350px;
    margin-bottom: 20px;
  }
}