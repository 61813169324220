@import '../../../../scss/presets/colors';
@import '../../../../scss/presets/mixins';
@import '../../../../scss/presets/consts';

.slide {
  width: 100%;
  height: 100%;

  &__leftSide, &__rightSide {
    height: 640px;
    min-height: 640px;
    max-height: 640px;
  }

  &__leftSide {
    @include flexAlignItems(column !important, nowrap, space-between, center);
    padding-bottom: 28px;
    position: relative;

    &-decorCircle {
      min-width: 350px;
      min-height: 350px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, .75);
    }

    &-drugImage {
      position: absolute;
      width: 80%;
      top: 100px;
      left: 10%;
    }

  }

  &__rightSide {
    position: relative;
    width: 100%;
    height: auto;
    & > img {
      width:100%;
      height: auto;
      max-width: 367px;
      max-height: 466px;
      position: absolute;
      right: 0;
      left: 0;
    }
    &-mainImage, &-animateImage {
      margin: 0 auto;
    }
  }

  &__bottomSide {
    height: 120px;
    max-height: 120px;
    padding: 5px;

    &-content {
      background-color: $ColorF8;
      height: 100%;
      border-radius: 54px;
      padding: 0 28px 0 80px;
      @include flexAlignItems(row, nowrap, space-between, center);
      @include appShadow();

      & > h1 {
        @include font($Color30, 700, 26px, 32px);
        text-transform: uppercase;
        margin-right: 70px;
        flex-grow: 1;
      }

      & > .mainPageLinkButton {
        padding: 0;
        flex-shrink: 0;

        & > button {
          background-color: $Color82;
        }
      }
    }
  }

  &__buttons-mobile {

    & > div.mainPageLinkButton {
      display: none;
    }
  }
}

@media screen and (max-width: $lg) {
  .slide {
    margin-top: 50px;
    &__bottomSide {
      &-content {
        & > h1 {
          @include font($Color30, 700, 20px, 28px);
        }
      }
    }
    &__leftSide {
      padding-bottom: 0;
      &-drugImage {
        width: 100%;
        left: 0;
      }

      &-decorCircle {
        min-width: 300px;
        min-height: 300px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, .75);
      }
    }


    &__rightSide {

      & img {
        max-width: 300px;
      }

    }

    &__leftSide, &__rightSide {
      height: 500px;
      min-height: 500px;
      max-height: 500px;
      padding-top: 50px;
    }
  }
}

@media screen and (max-width: $md) {
  .slide {

    &__bottomSide {
      &-content {
        padding: 0 28px 0 60px;
        & > .mainPageLinkButton {
          & > button {
            background-color: $Color82;
          }
        }
        & > h1 {
          @include font($Color30, 700, 18px, 22px);
          margin-right: 10px;
        }
      }
    }
    &__leftSide, &__rightSide {
      height: 440px;
      min-height: 440px;
      max-height: 440px;
    }

    &__leftSide {
      &-decorCircle {
        min-width: 280px;
        min-height: 280px;
      }
    }
  }
}

@media screen and (max-width: $sm) {
  .slide {
    margin-top: 0;
    &__rightSide {
     display: none;
    }
    &__leftSide {
      padding-top: 0px;
      max-width: 480px !important;
      margin: 0 auto !important;
      max-height: 500px;
      min-height: unset;
      justify-content: center;
      gap: 30px;
      &-drugImage {
        right: 0;
        left: 0;
        max-width: 350px;
        margin: 0 auto;
      }
      &-decorCircle {
        min-width: 268px;
        min-height: 268px;
      }
    }

    &__bottomSide {
      display: none;
    }
    &__buttons-mobile {
      display: flex;
      margin-top: -10px;
      justify-content: space-around;
      width: 100%;
      max-width: 320px;
      & > div.mainPageLinkButton {
        display: block;
        & > button {
          background-color: $Color82;
        }

      }
    }
  }
}