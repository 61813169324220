@import '../../../../scss/presets/colors';
@import '../../../../scss/presets/mixins';
@import '../../../../scss/presets/consts';

.knowledgeBlock {
  margin: 0;
  padding-bottom: 75px;
  background: url('../../../../assets/img/backgrounds/lightBg.jpg') no-repeat center top;
  background-size: 100% 100%;

  &__cardsArea {
    @include flexAlignItems(row, nowrap, space-around, center);
    margin-top: 50px;
  }
}
@media screen and (max-width: $md) {
  .knowledgeBlock {
    padding-bottom: 0;
    background: url('../../../../assets/img/backgrounds/lightBg-t.jpg') no-repeat center top;
    background-size: 100% auto;
    &__cardsArea {
      padding-bottom: 100px;
    }
  }
}

@media screen and (max-width: $sm) {
  .knowledgeBlock {
    margin: 0 0 25px;
    background: url('../../../../assets/img/backgrounds/lightBg-m.jpg') no-repeat center top;
    background-size: 100% 100%;
    &__cardsArea {
      @include flexAlignItems(column, nowrap, space-around, center);
      margin-top: 50px;
    }
  }
}