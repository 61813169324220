@import '../../../scss/presets/colors';
@import '../../../scss/presets/mixins';

.page404 {
  & > .app__container {
    @include flexAlignItems(column, nowrap, center, center);
  }
  @include flexAlignItems(column, nowrap, center, center);
  background: linear-gradient(180deg, #51C1EA 0%, #EEFCFE 70.83%);
  z-index: 50;
  img {
    width: 85px;
    height: 70px;
  }

  h3 {
    @include font($Color23, 700, 32px, 38px);
    margin-bottom: 14px;
  }

  h5 {
    @include font($Color30, 700, 32px, 38px);
    text-align: center;
  }

  p {
    @include font($Color23, 900, 20px, 28px);
    text-align: center;
  }
}