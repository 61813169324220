@import '../../../scss/presets/colors';
@import '../../../scss/presets/mixins';
@import '../../../scss/presets/consts';

.blog {
  background: url('../../../assets/img/backgrounds/bgWave.jpg') no-repeat center top;
  background-size: contain;
  &__imageArea {
    width: 100%;
    border-radius: 31px;
    max-height: 547px;
    padding-bottom: 0;
    box-sizing: content-box;
    position: relative;
    z-index:10;
    object-fit: cover;
  }
  &__content {
    padding: 25px 16px 60px;
    width: 100%;

    h5 {
      @include font($Color23, 700, 30px, 36px);
      margin-top: 58px;
      margin-bottom: 30px;
    }

    p {
      @include font($Color30, 400, 16px, 22px);
      padding-bottom: 16px;

      & a {
        @include font($Color30, 400, 16px, 22px);
      }
      & h2 {
        @include font($Color30, 700, 30px, 36px);
        padding-bottom: 8px;
      }
      & h3 {
        @include font($Color30, 300, 30px, 36px);
        padding-bottom: 8px;
      }
      & h4 {
        @include font($Color30, 700, 20px, 24px);
        padding-bottom: 8px;
      }
    }

    &-buttonArea {
      @include flexAlignItems(row, wrap, space-between, center);
    }
  }

  &__cardsArea {
    @include flexAlignItems(row, wrap, flex-start, flex-start);
    gap: 13px;
    padding: 30px 0 100px;
  }
}
@media screen and (max-width: $lg) {
  .blog {
    &__cardsArea {
      padding: 30px 0 ;
    }
  }
}

@media screen and (max-width: $md) {
  .blog {
    background: url('../../../assets/img/backgrounds/bgWave-t.jpg') no-repeat center top;
    background-size: contain;
    &__imageArea {
      max-height: 334px;
      padding-bottom: 20px;
    }
    &__content {
      h5 {
        @include font($Color23, 700, 28px, 34px);
        margin: 0 0 10px 0;
      }
    }
  }
}

@media screen and (max-width: $sm) {
  .blog {
    background: url('../../../assets/img/backgrounds/bgWave-m.jpg') no-repeat center top;
    background-size: contain;
    &__imageArea {
      max-height: 151px;

    }
    &__content {
      padding-top: 0;
    }
  }

}