@import '../../scss/presets/colors';
@import '../../scss/presets/mixins';
@import '../../scss/presets/consts';

html {
  scroll-behavior: smooth;
}

.app {
  min-height: 100vh;

  &__container {
    max-width: 1320px;
    margin: 0 auto;
    width: 100%;
    padding: 0 16px;
  }

  &__blockHeight {
    min-height: calc( 100vh - 370px);
  }

  &__decor {
    position: absolute;
    width: 1000px;
    height: 500px;
    background-color: rgba(255, 255, 255, 0.76);
    opacity: 0.31;
    right: 70px;
    top: 0;
    z-index: 1;
    pointer-events: none;
    border-radius: 0 0 50% 50% / 0 0 100% 100%;
  }
}
@media screen and (max-width: $xl) {
  .app {
    &__container {
      max-width: 1240px !important;
    }
  }
}

@media screen and (max-width: $lg) {
  .app {
    &__container {
      width: 890px !important;
    }
    &__decor {
      width: 75%;
      height: calc(75vw * 0.5);
      right: 10px;
    }
  }
}

@media screen and (max-width: $md) {
  .app {
    &__container {
      width: 100% !important;
    }
    &__decor {
      width: 75%;
      height: calc(75vw * 0.5);
      right: 10px;
    }
  }
}
@media screen and (max-width: $sm) {
  .app {
    &__container {
      width: 100% !important;
    }
  }
}

@media screen and (max-width: $xs) {
  .app {
    &__container {
      width: 100% !important;
    }
  }
}