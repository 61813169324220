@import '../../../../scss/presets/colors';
@import '../../../../scss/presets/mixins';
@import '../../../../scss/presets/consts';

.mainPageLinkButton {
  @include flexAlignItem(center, center);
  padding: 25px 0;

  & > button {
    cursor: pointer;
    display: flex;
    padding: 17px 26px;
    border-radius: 83px;
    background-color: $Color23;
    border: none;
    outline: none;
    @include font($ColorFF, 900, 20px, 24px);
    @include appShadow();
    @include hoverOpacityStyle();
  }

  &__imageArea {
    img {
      margin-left: 14px;
    }
  }
}

@media screen and (max-width: $md) {
  .mainPageLinkButton {
    padding: 25px 0;
    & > button {
      cursor: pointer;
      padding: 17px 20px;
      @include font($ColorFF, 900, 18px, 22px);
    }
  }
}

@media screen and (max-width: $sm) {
  .mainPageLinkButton {
    margin-right: 0;
    & > button {
      display: flex;
      align-items: flex-start;
      padding: 8px 12px;
      @include font($ColorFF, 700, 12px, 14px);
      flex-grow: 2;
      white-space: nowrap;
      max-width: 210px;
      justify-content: center;
    }

    &__imageArea {
      margin-left: 4px;
      width: 7px;
      height: 10px;
      img {
        width: 100%;
        margin-left: 0;
        height: 100%;
      }
    }
  }
}