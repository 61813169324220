@import '../../../../scss/presets/colors';
@import '../../../../scss/presets/mixins';
@import '../../../../scss/presets/consts';

.mainSlider {
  background: url('../../../../assets/img/backgrounds/bgWave.jpg') no-repeat center top;
  background-size: 100% 100%;
  padding-bottom: 0;
  & > .app__container {
    & > .swiper {
      max-height: 760px;
      width: 100%;

      & > .swiper-pagination {
        bottom: 180px;
        right: 0;
        //left: calc(50% + 10px);
        left: unset;
        width: 800px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;

        & > .swiper-pagination-bullet {
          //opacity: 0;
          margin-right: 34px;
          width: auto;
          background-color: transparent;
          opacity: 0.5;

          &:before {
            @include font($ColorFF, 900, 20px, 24px);
            width: auto;
            padding: 7px 17px;
            border-radius: 44px;
          }
        }

        & > .swiper-pagination-bullet:nth-child(1):before {
          content: 'SUCHE OKO';
          background-color: $ColorF4A;
        }

        & > .swiper-pagination-bullet:nth-child(2):before {
          content: 'CZERWONE OKO';
          background-color: $ColorEB;
        }

        & > .swiper-pagination-bullet:nth-child(3):before {
          content: 'SZTUCZNE ŁZY';
          background-color: $Color90;
        }

        & > .swiper-pagination-bullet:nth-child(4):before {
          content: 'AMD';
          background-color: $ColorF4D;
        }

        & > .swiper-pagination-bullet-active {
          opacity: 100%;
        }
      }
    }
  }
}

.swiper-slide-active > .slide .slide__rightSide-animateImage {
  opacity: 0;
  animation-name: showImage;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-delay: 2s;
  animation-direction: alternate;
}

@keyframes showImage {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 100%;
  }
  100% {
    opacity: 100%;
  }
}

@media screen and (max-width: $lg) {
  .mainSlider {
    & > .app__container {
      & > .swiper {
        max-height: 760px;
        width: 100%;

        & > .swiper-pagination {
          top: 10px;
          & > .swiper-pagination-bullet {
            margin-left: 30px;
            margin-right: 0;
          }
        }
      }
    }
  }
}
@media screen and (max-width: $md) {
  .mainSlider {
    background: url('../../../../assets/img/backgrounds/bgWave-t.jpg') no-repeat center top;
    background-size: 100% 100%;
    padding-bottom: 30px;
    & > .app__container {
      & > .swiper {
        & > .swiper-pagination {
          & > .swiper-pagination-bullet {
            &:before {
              @include font($ColorFF, 900, 16px, 22px);
              width: auto;
              padding: 7px 10px;
              border-radius: 44px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: $sm) {
  .mainSlider {
    background: url('../../../../assets/img/backgrounds/bgWave-m.jpg') no-repeat center top;
    background-size: 100% 100%;
    padding-bottom: 0;
    & > .app__container {
      & > .swiper {
        max-height: 800px;
        min-height: 460px;
        width: 100%;
        & > .swiper-pagination {
          display: none;
        }
      }
    }
  }
}