@import '../../../../scss/presets/colors';
@import '../../../../scss/presets/mixins';
@import '../../../../scss/presets/consts';

.buyButton {
  @include flexAlignItem(center, center);
  width: 100%;
  z-index: 100;

  &__buttonWrapper {
    position: relative;
  }

  &__mainButton {
    cursor: pointer;
    width: 225px;
    height: 60px;
    margin-top: 20px;
    border-radius: 83px;
    border: none;
    position: relative;
    outline: none;
    z-index: 11;
    @include flexAlignItems(row, nowrap, center, center);
    @include appShadow();
    @include font($ColorFF, 700, 20px, 24px);
    background-color: $ColorEB;
  }

  &__imageArea {
    width: 29px;
    height: 29px;
    margin-right: 10px;
   & img {
      width: 100%;
      height: 100%;
    }
  }

  a {
    width: 150px;
    height: 30px;
    border-radius: 83px;
    @include flexAlignItems(row, nowrap, center, center);
    @include appShadow();
    @include font($ColorFF, 400, 15px, 18px);
    text-decoration: none;
    background-color: $ColorEB;
  }

  &__dropZone {
    width: 225px;
    position: absolute;
    top: 40px;
    padding: 42px 0 20px 0;
    left: 0;
    display: none;
    z-index: 10;
    list-style: none;
    border-bottom-left-radius: 33px;
    border-bottom-right-radius: 33px;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%);

    img {
      width: 15px;
      min-width: 15px;
      height: 15px;
      min-height: 15px;
      margin-right: 5px;
    }

    & > li:not(:last-child) {
      margin-bottom: 15px;
    }

    &-show {
      @include flexAlignItems(column, nowrap, flex-start, center);
      //display: block;
    }
  }
}
@media screen and (max-width: $sm) {
  .buyButton {
    margin-left: 0;
    justify-content: flex-start;
    &__mainButton {
      height: 31px;
      @include font($ColorFF, 700, 12px, 14px);
      padding: 8px 12px;
      margin: 0;
      max-width: 100%;
      position: relative;
      z-index: 11;
      width: 100%;
    }
    &__imageArea {
      width: 18px;
      height: 17px;
      margin-right: 7px;
    }
    &__dropZone {
      max-width: 147px;
      width: 100%;
      position: absolute;
      top: 18px;
      bottom: unset;
      padding: 20px 0 16px 0;
      left: 0;
      margin: 0;
      & > li:not(:last-child) {
        margin-bottom: 8px;
      }
    }


    a {
      width: 102px;
      padding: 1px 5px;
      @include font($ColorFF, 700, 11px, 20px);
      display: inline-flex;
      height: 20px;
    }
  }
}