@import '../../../scss/presets/colors';
@import '../../../scss/presets/mixins';
@import '../../../scss/presets/consts';


.knowledgeVideo {
  background: linear-gradient(180deg, #51C1EA 0%, #EEFCFE 70.83%);


  & > .app__container {
    @include flexAlignItems(column, nowrap, flex-start, flex-start);
  }

  &__cardsArea {
    z-index:40;
    @include flexAlignItems(row, wrap, flex-start, center);
    gap: 15px;
    margin: 25px auto 0 auto;
  }
}

@media screen and (max-width: $md) {
  .knowledgeVideo {
    &__cardsArea {
      @include flexAlignItems(row, wrap, space-around, center);
      flex: 0 1 auto;
    }
  }
}

@media screen and (max-width: $sm) {
  .knowledgeVideo {
    & > .app__container {
      @include flexAlignItems(column, nowrap, flex-start, flex-start);
    }

    &__cardsArea {
      @include flexAlignItems(column, wrap, flex-start, center);
    }
  }
}