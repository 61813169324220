@import "../../../../scss/presets/colors";
@import "../../../../scss/presets/mixins";
@import "../../../../scss/presets/consts";

.productsBlock {
  padding: 0;
  background-color: $ColorFF;
  margin-bottom: 0;

  &__cardsArea {
    margin: 25px 0;

    .swiper-button-prev,
    .swiper-button-next {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background: url("../../../../assets/img/swiperArrow.png");
      top: 185px;

      &:after {
        content: '';
      }
    }

    .swiper {
      .swiper-horizontal>.swiper-pagination-bullets,
      .swiper-pagination-bullets.swiper-pagination-horizontal,
      .swiper-pagination-custom, .swiper-pagination-fraction {
        bottom: 0;
      }
    }

    .swiper-button-next {
      rotate: 180deg;
    }
    .swiper {
      padding: 0 0 16px 0;
    }
  }
  & div.mainPageLinkButton {
    padding: 0 0 25px 0;
  }
}

@media screen and (max-width: $sm) {

  .productsBlock {
    &__cardsArea {
      .swiper-button-prev,
      .swiper-button-next {
        width: 50px;
        height: 50px;
        background-size: contain;
      }
    }
  }
}
