@import '../../../scss/presets/colors';
@import '../../../scss/presets/mixins';
@import '../../../scss/presets/consts';

.dropDownMenu {
  position: relative;

  &__dropZone {
    position: absolute;
    bottom: -163px;
    padding: 20px 0 5px;
    left: 0;
    display: none;
    z-index: 102;
    list-style: none;

    & > li:not(:last-child) {
      margin-bottom: 25px;
    }

    &-show {
      display: block;
      padding-top: 30px;
      z-index: 102;
    }
  }
}
@media screen and (max-width: $md) {
  .dropDownMenu {
    position: relative;

      &__dropZone {
        bottom: -150px;
      }
    }
}

@media screen and (max-width: $sm) {
  .dropDownMenu {
    display: none;
  }
}