@import '../../../scss/presets/colors';
@import '../../../scss/presets/mixins';
@import '../../../scss/presets/consts';

.productList {
  background: url('../../../assets/img/backgrounds/bgWave.jpg') no-repeat center top;
  background-size: cover;
  min-height: 900px;

  &__cardsArea {
    display: flex;
    margin: 50px 0 140px 0;
    @include flexAlignItems(row, wrap, flex-start, flex-start);
    gap: 12px;
  }
}

@media screen and (max-width: $xl) {
  .productList {
    min-height: 800px;
    &__cardsArea {
      display: flex;
      margin: 50px 0 40px 0;
      @include flexAlignItems(row, wrap, flex-start, flex-start);
      gap: 12px;
    }
  }
}
@media screen and (max-width: $md) {
  .productList {
    background: url('../../../assets/img/backgrounds/bgWave-t.jpg') no-repeat center top;
    background-size: contain;
    &__cardsArea {
      @include flexAlignItems(row, wrap, space-around, start);
      flex: 0 1 auto;
    }
  }
}

@media screen and (max-width: $sm) {

  .productList {
    background: url('../../../assets/img/backgrounds/bgWave-m.jpg') no-repeat center top;
    background-size: contain;
    &__cardsArea {
      @include flexAlignItems(column, wrap, flex-start, center);
    }
  }
}