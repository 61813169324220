@import '../../../../scss/presets/colors';
@import '../../../../scss/presets/mixins';
@import '../../../../scss/presets/consts';

.qaCard {
  background-color: $ColorEE;
  z-index: 100;
  @include hoverBoxShadowStyle();

  &-title {
    height: 52px;
    padding: 0 25px 0 18px;
    @include flexAlignItems(row, nowrap, flex-start, center);
    cursor: pointer;

    & > img:first-child {
      margin-right: 24px;
      min-width: 36px;
      min-height: 28px;
    }

    & > p {
      @include font($Color23, 900, 20px, 24px);
      flex-grow: 1;
    }

    & > img:last-child {
      min-width: 10px;
      min-height: 16px;
    }
  }

  &-content {
    @include font($Color30, 400, 16px, 22px);
    padding: 10px 61px 21px 75px;
  }
  &__image {
    transform: rotate(0deg);
    transition: transform 0.2s;
    &-active {
      transform: rotate(90deg);
      transition: transform 0.2s;
    }
  }
}

.qaCard:nth-child(2n){
  background-color: $ColorFF;
}

@media screen and (max-width: $sm) {
  .qaCard {
    &-title {
      & > p {
        @include font($Color23, 400, 14px, 17px);
      }
    }
  }
}