@import '../../../../scss/presets/colors';
@import '../../../../scss/presets/mixins';
@import '../../../../scss/presets/consts';

.productCard {
  max-width: 600px;
  margin: 0 auto;
  flex-basis: 32%;
  cursor: pointer;
  z-index: 40;

  &__imgArea {
    width: 100%;
    height: 395px;
    @include flexAlignItem(center, center);
    background: linear-gradient(180deg, rgba(81, 193, 234, 0.28) 0%, #FFFFFF 65.62%);
    border-radius: 31px;
    @include hoverBoxShadowStyle();
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  &__title {
    padding: 15px 20px;
    min-height: 140px;
    word-break: break-word;

    &-name {
      @include font($Color30, 700, 30px, 36px);
    }

    &-description {
      @include font($Color30, 300, 30px, 36px);
    }
  }
}

@media screen and (max-width: $xl) {

  .productCard {
    flex-basis: 32%;
    max-width: 600px;;
  }
}
@media screen and (max-width: $lg) {

  .productCard {
    flex-basis: 32%
  }
}
@media screen and (max-width: $md) {
  .productCard {
    flex-basis: 48%;

    &__title {
      padding: 0 16px;
    }
  }
}

@media screen and (max-width: $sm) {
  .productCard {
    width: 100%;
    max-width: 350px;

    &__imgArea {
      height: 380px;
    }
  }
}