@import '../../../../scss/presets/colors';
@import '../../../../scss/presets/mixins';
@import '../../../../scss/presets/consts';

.productSubTitle {
  width: 100%;
  min-height: 140px;
  padding-top: 50px;
  @include flexAlignItems(row, nowrap, flex-start, flex-start);

  &__imgWrapper {
    @include flexAlignItem(flex-end, flex-end);
    flex-shrink: 0;

    & > div {
      width: 105px;
      height: 105px;
      min-width: 105px;
      min-height: 105px;
      border-radius: 50%;
      background-color: $Color5F;
    }

    img {
      width: 100%;
      margin-left: -50px;
    }
  }

  &__textWrapper {
    flex-grow: 1;
    padding-left: 35px;

    h3 {
      @include font($Color23, 900, 20px, 24px);
      margin-bottom: 30px;
      text-transform: uppercase;
    }

    ul, p {
      @include font($Color30, 400, 16px, 18px);
      list-style: disc;
      margin: 0;
    }

    ul {
      padding-left: 20px;
    }
  }
}

.productSubTitle:not(:last-child) {
  margin-bottom: 40px;
  min-height: 187px;
}

@media screen and (max-width: $md) {

  .productSubTitle {
    &__textWrapper {
      h3 {
        @include font($Color23, 900, 16px, 20px);
        margin-bottom: 30px;
        text-transform: uppercase;
      }
       ul, li, p {
        @include font($Color30, 400, 14px, 18px);
      }
    }
  }
}

@media screen and (max-width: $sm) {
  .productSubTitle {
    flex-direction: column;
    margin: 0 auto;
    &__textWrapper {
      padding-left: 16px;
      h3 {
        margin-top: 20px;
      }
      ul, li, p {
        @include font($Color30, 400, 16px, 18px);
      }
    }


    &__imgWrapper {
      margin: 0 auto;

      & > div {
        background-color: $Color5F;
      }

    }
  }
  .productSubTitle {
    margin-bottom: 20px;
  }
  .productSubTitle:not(:last-child) {
    margin-bottom: 20px;
  }
}