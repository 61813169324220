*, *:before, *:after{
  box-sizing: border-box;
  font-family: $mainFont;
  font-size: 16.5px;
}

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video{
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  text-size-adjust: none;
}

ol, ul {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 40px;
}

sup{
  font-size:0.7em;
}