@import "../../../scss/presets/colors";
@import "../../../scss/presets/mixins";
@import "../../../scss/presets/consts";

.footer {
  display: flex;
  flex-direction: column;
  margin-top: 0;
  padding-top: 0;

  & div.app__container {
    width: 100%;

  }

  &__container {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__main {
    width: 1000px;
    margin: 25px auto 0 auto;
    padding: 30px 0;
    z-index: 2;
    flex-grow: 1;
    overflow: hidden;
    max-height: 294px;
    bottom: 0;
    background: url("../../../assets/img/footerBG.png") top;
    background-repeat: no-repeat;
    @include flexAlignItems(column, nowrap, flex-start, center);
    h4 {
      margin: 10px 0 17px;
      @include font($ColorEE, 700, 20px, 24px);
    }

    & > p {
      @include font($ColorEE, 400, 16px, 19px);
      text-align: center;

      & > span, & > a {
        color: $ColorEE;
        font-weight: 700;
        text-decoration: none;
      }
    }
  }
  &__rules {
    display: flex;
    position: absolute;
    z-index:1;
    padding: 9px 9px 13px 9px;
    justify-content: space-between;
    background-color: $ColorFF;
    align-items: center;
    gap: 5px;
    width: 100%;
    bottom: 0;
    margin: 0 auto;
    @include font($Color23, 700, 12px, 14px);

    & div:last-child {
      flex-wrap: nowrap;
      display: flex;
      flex-shrink: 0
    }
    & img {
      margin: 0 10px;
      vertical-align: middle;
    }
    & a {
      @include font($Color23, 700, 12px, 14px);
    }
  }
}
@media screen and (max-width: $xl) {
  .footer {
    & div.app__container {
      width: 100% !important;
    }
    &__container {
      display: flex;
      flex-direction: column;
    }
    &__main {
      width: 100%;
      margin-bottom: 0;
      height: unset;
      padding-bottom: 25px;
    }

    &__rules {
      justify-content: space-around;
      width: 100%;
      margin: 0 auto;
      position: unset;
    }
    & div.app__container {
      padding: 0;
    }
  }
}
@media screen and (max-width: $xl) {
  .footer {
    &__rules {
      justify-content: space-between;
      width: 100%;
    }
  }
}
@media screen and (max-width: $md) {
  .footer {
    &__rules {
    justify-content: space-between;
    width: 100%;
    }
  }
}
