@import '../../../../scss/presets/colors';
@import '../../../../scss/presets/mixins';
@import '../../../../scss/presets/consts';

.fileCard {
  width: 48%;
  height: 117px;
  padding: 0 34px;
  border-radius: 31px;
  background-color: $ColorEE;
  margin-top: 25px;
  @include flexAlignItems(row, nowrap, flex-start, center);
  text-decoration: none;
  z-index: 100;
  @include hoverBoxShadowStyle();

  img {
    width: 59px;
    min-width: 59px;
    height: 73px;
    min-height: 73px;
    margin-right: 20px;
  }

  p {
    @include font($Color23, 900, 23px, 28px);
  }
}

@media screen and (max-width: $md) {
  .fileCard {
    max-width: 732px;
    width: 100%;
    height: unset;
    padding: 12px 20px;

    p {
      @include  font($Color23, 700, 20px, 24px);
    }
  }
}